$primary-color: #8eae3f;
$dark: #343a40;
$white-color: #fff;
$black-color: #000000;
$background-green :#F6F9F0;

// =========================================
// =Mobile responsive settings code snippet=
// =========================================
$breakpoints: (
  "exsmall_320": (
    max-width: 325px,
  ),
  "exsmall": (
    max-width: 375px,
  ),
  "exsmall_574": (
    max-width: 574px,
  ),
  "small": (
    max-width: 767px,
  ),
  "medium": (
    max-width: 991px,
  ),
  "medium_lg": (
    max-width: 1024px,
  ),
  "large": (
    max-width: 1199px,
  ),
  "ex_large_max": (
    max-width: 1440px,
  ),
  "ex_large": (
    max-width: 1920px,
  ),
  "min768": (
    min-width: 768px,
  ),
) !default;

@mixin respond-to($breakpoint) {
    @if map-has-key($breakpoints, $breakpoint) {
      @media #{inspect(map-get($breakpoints, $breakpoint))} {
        @content;
      }
    } @else {
      @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
          + "Available breakpoints are: #{map-keys($breakpoints)}.";
    }
  }