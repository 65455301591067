// =========================================
// ========== Home page styles =============
// =========================================
.home-main-wrapper {
  .hero{
    position: relative;
    height: 100vh;
    background: url('../../public/mountains_hero_img.jpg')no-repeat center center/cover;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    // @include respond-to("small"){
    //   background: url('../../public/mountains_hero_img_mob.jpg')no-repeat center center/cover;
    // }
    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      // background-color: rgba(0, 0, 0, 0.5);
      z-index: 1;
  }
  .hero-text {
    position: relative;
    z-index: 2;
    max-width: 1480px;
    padding: 150px 0 40px;
    -webkit-box-pack: center;
    justify-content: center;
    margin: 0 auto;
    height: 100%;
    min-height: calc(var(--vh, 1vh)* 100);
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    width: 80%;
    .title-holder{
      transform: translateY(-45%);
      @include respond-to("small"){
        transform: translateY(-90%);
      }
      h1{
        font-size: 72px;
        margin-bottom: 30px;
        font-style: normal;
        font-weight: 700;
        line-height: 128%; /* 92.16px */
        color: $white-color;
        width: 70%;
        margin: 0 auto;
        
        @include respond-to("ex_large_max"){
          font-size: 52px;
        }
        @include respond-to("small"){
          font-size: 28px;
          width: 80%;
          text-align: left;
          margin: 0;
        }
      }
    }
    .hero-cards-wrapper{
      position: absolute;
      left: 0;
      right: 0;
      bottom: 110px;
      .cards-list{
        display: flex;
        justify-content: center;
        gap: 40px;
        @include respond-to("small"){
          flex-direction: column;
          gap: 12px;
        }
        .stat {
          text-align: center;
          width: 30%;
          align-items: center;
          justify-content: center;
          display: flex;
          padding: 24px;
          border-radius: 8px;
          border-left: 12px solid #8EAE3E;
          background: rgba(0, 0, 0, 0.50);
          // backdrop-filter: blur(2px);
          gap: 12px;
          @include respond-to("small"){
            width: 80%;
            justify-content: flex-start;
          }
          .num-holder{
            h2{
              font-size: 48px;
              color: $white-color;
              @include respond-to("small"){
                font-size: 32px;
              }
            }
          }
          .text-holder{
            width: 40%;
            max-width: 100px;
            p {
              font-size: 20px;
              color: $white-color;
              @include respond-to("small"){
              font-size: 12px;
              }
          }
          }
          
          &:hover{
            background: #8EAE3E;
            animation: fade-in-keyframes 500ms linear;
            @keyframes fade-in-keyframes {
              from {
                opacity: 0;
              }
              to {
                opacity: 1;
              }
            }
          }
        }
      }

     
    }
  }
  }
  // .home-banner {
  //   min-height: 600px;
  //   //background-color: #f1f1f1;
  //   //padding-top: 70px;
  //   //padding-bottom: 70px;
  //   // display: flex;
  //   // align-items: center;
  //   // justify-content: center;
  //   @include respond-to("medium_lg") {
  //     min-height: 0px;
  //     align-items: flex-start;
  //   }
  //   @include respond-to("small") {
  //     padding-top: 20%;
  //     padding-bottom: 0px;
  //   }
  //   .video-thumb {
  //     width: 100%;
  //     height: 100%;
  //     transition: opacity 400ms ease 1s;
  //   }
  //   video {
  //     display: block;
  //   }
  // }
}
//-------------------------value section--------------------
.our-value-wrapper {
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.16);
  //height: 250px;
  padding: 140px 0;
  background-color: #f1f1f1;
  @include respond-to("medium") {
    padding: 88px 0;
    height: 202px;
  }
  @include respond-to("small") {
    height: 150px;
    padding: 25px 0 0px;
  }
  .our-value {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    //top: -128px;
    box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
    height: 243px;
    @include respond-to("medium_lg") {
      height: 148px;
    }
    @include respond-to("medium") {
      height: 100px;
    }
    @include respond-to("small") {
      height: 66px;
    }
    .value-box {
      text-align: center;
      padding: 0 33px;
      background-image: url('../../public/value_icon/White_icon.svg');
      background-repeat: no-repeat;
      height: 324px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 222px;
      background-position: center;
      position: relative;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
      border-radius: 12px;
      margin: 15px;

      @include respond-to("medium_lg") {
        width: 141px;
        height: 227px;
      }

      @include respond-to("medium") {
        width: 110px;
        height: 200px;
      }
      @include respond-to("small") {
        background:url('../../public/value_icon/Mobile_White_icon.svg');
        width: 70px;
        height: 70px;
        border-radius: 4px;
      }
      .num {
        font-size: 72px;
        font-weight: 600;
        line-height: 1;
        color: #646466;
        text-align: center;
        position: absolute;
        bottom: 40%;
        @include respond-to("medium_lg") {
          text-align: center;
          font-size: 50px;
        }
        @include respond-to("medium") {
          font-size: 40px;
          text-align: center;
        }
        @include respond-to("small") {
          font-size: 18px;
          text-align: center;
          bottom: 46%;
        }
      }
      .text {
        font-size: 24px;
        font-weight: 600;
        //padding-bottom: 15px;
        color: #646466;
        position: absolute;
        top: 60%;
        @include respond-to("medium_lg") {
          text-align: center;
          font-size: 20px;
        }
        @include respond-to("medium") {
          font-size: 17px;
          text-align: center;
        }
        @include respond-to("small") {
          font-size: 12px;
          text-align: center;
          top: 57%;
          line-height: 1;
        }
      }
      &.center {
        background: url("../../public/value_icon/Green_icon.svg");
        background-repeat: no-repeat;
        height: 368px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
        width: 292px;
        background-position: center;
        position: relative;
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
        border-radius: 12px;
        @include respond-to("medium_lg") {
          width: 192px;
          height: 285px;
        }
        @include respond-to("medium") {
          width: 141px;
          height: 220px;
        }
        @include respond-to("small") {
          background: url("../../public/value_icon/Mobile_Green_icon.svg");
          width: 79px;
          height: 79px;
          border-radius: 4px;
        }
        .num {
          color: #ffffff;
          position: absolute;
          bottom: 55%;
          //padding-right: 43px;
          @include respond-to("medium") {
            font-size: 37px;
            padding-right: 0;
          }
          @include respond-to("small") {
            padding-right: 0;
            bottom: 60%;
            font-size: 17px;
          }
        }
        .text {
          position: absolute;
          bottom: 13%;
          // padding-bottom: 60px;
          // padding-top: 10px;
          // padding-right: 26px;
          color: #f1f1f1;
          font-size: 36px;
          font-weight: 600;
          text-align: center;
          top: 48%;
          @include respond-to("medium_lg") {
            font-size: 30px;
          }
          @include respond-to("medium") {
            padding-right: 0;
            font-size: 24px;
          }
          @include respond-to("small") {
            padding-right: 0;
            padding-top: 0;
            font-size: 14px;
            font-weight: 600;
            top: 44%;
          }
        }
      }
      @include respond-to("medium") {
        padding: 0 35px;
      }
      @include respond-to("small") {
        padding: 13px 6px;
        margin: 10px;
      }
    }
    @include respond-to("medium") {
      top: 26px;
    }
  }
}

//------------------- Slider section -------------------------------
.logo-slider {
  min-height: 400px;
}
//------------------- Our team section -----------------------------
.our-team-wrapper {
  background: #646466;
  // padding: 160px 0 50px 0;
  padding: 80px 0px 0px;
  @include respond-to("small") {
    background-color: #f1f1f1;
    padding: 60px 0 50px 0;
  }
  .section-title {
    text-align: center;
    width: 40%;
    margin: 0 auto;
    @include respond-to("medium") {
      width: 80%;
    }
    h2 {
      color: #ffffff;
      font-size: 48px;
      font-weight: 600;
      margin-bottom: 16px;
      @include respond-to("small") {
        font-family: Poppins;
        font-size: 18px;
        font-weight: 600;
        line-height: 1.5;
        letter-spacing: 0.65px;
        text-align: center;
        color: #484848;
      }
    }
    p {
      color: #d5d5d5;
      font-size: 18px;
      font-weight: 300;
      text-align: center;
      @include respond-to("small") {
        opacity: 0.6;
        font-family: Poppins;
        font-size: 12px;
        font-weight: 300;
        line-height: 1.6;
        letter-spacing: normal;
        text-align: center;
        color: #000000;
        padding: 0 28px 0;
      }
    }
  }
  .team-card-wrapper {
    display: flex;
    align-items: center;
    padding: 80px 100px;
    flex-flow: row wrap;
    flex: 1 1 auto;
    justify-content: center;
    @include respond-to("medium_lg") {
      padding: 80px 60px;
    }
    @include respond-to("medium") {
      padding: 80px 29px;
    }
    @include respond-to("small") {
      display: none;
    }

    .team-card {
      display: block;
      width: 24%;
      display: flex;
      flex-direction: column;
      margin: 22px;
      cursor: pointer;
      //transition: transform 0.5s;
      @include respond-to("medium_lg") {
        width: 21%;
      }
      @include respond-to("medium") {
        width: 26%;
        margin: 15px;
      }
      @include respond-to("small") {
        flex-direction: row;

        //margin: 15px auto;
        //width: 40%;
      }
      img {
        object-fit: cover;
        width: 100%;
        // transition: transform 0.5s;
      }

      .img_color {
        // display: none;
        filter: grayscale(100%);
      }
      .details-box {
        background: #ffffff;
        padding: 10px;
        height: 63px;
        @include respond-to("medium_lg") {
          padding: 6px;
          height: 65px;
        }
        @include respond-to("small") {
          // height: 100%;
          // width: 100%;
        }
        .line-1 {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .name {
            font-size: 14px;
            font-weight: 600;
            color: #000000;
            @include respond-to("medium_lg") {
              font-size: 12px;
            }
            @include respond-to("small") {
              font-size: 14px;
              font-weight: 600;
              opacity: 0.6;
            }
          }
          .icon-set {
            display: flex;
            @include respond-to("small") {
              display: none;
            }
            a {
              display: block;
              line-height: 10px;
              img {
                width: 29px;
                margin-left: 12px;
                @include respond-to("medium_lg") {
                  width: 28px;
                  margin-left: 8px;
                }
                @include respond-to("medium") {
                  width: 15px;
                }
              }
            }
          }
        }
        .line-2 {
          .title {
            font-size: 14px;
            color: #000000;
            text-align: left;
            @include respond-to("medium_lg") {
              font-size: 12px;
            }
            @include respond-to("small") {
              font-size: 14px;
              font-weight: 300;
              opacity: 0.6;
              line-height: 1.5;
            }
          }
          .icon-set {
            display: none;
            @include respond-to("small") {
              width: 30px;
              display: flex;
            }
          }
          .desc {
            font-size: 12px;
            margin-top: 15px;
            visibility: hidden;
            opacity: 0;
            transition: visibility 0s, opacity 0.5s linear;
            height: 0;
            span {
              a {
                left: 50%;
                color: #8eae3f;
                line-height: 1.5;
                opacity: 0.7;
                font-family: Poppins;
                font-size: 12px;
                font-weight: 500;
                float: right;
                display: none;
                padding: 9px;
                margin: auto 20px;
              }
            }
          }
        }
      }
      &.none-active {
        // flex-grow: 0;
        width: 24%;
        display: flex;
        flex-direction: column;
        align-self: center;
        .details-box {
          .line-1 {
            .icon-set {
              display: none;
            }
          }
          .line-2 {
            display: none;
            span {
              a {
                display: none;
              }
            }
          }
        }
      }

      &.active {
        transform: scale(1);
        // flex-grow: 1;
        .img_color {
          // display: block;
          filter: grayscale(0%);
        }
        .img_black {
          display: none;
        }
        animation: fade-in-keyframes 0.2s ease-in-out;
        @keyframes fade-in-keyframes {
          from {
            opacity: 0;
            transform: scale(0.8);
          }
          to {
            opacity: 1;
            transform: scale(1);
          }
        }
        width: 30%;
        @include respond-to("medium") {
          width: 60%;
        }
        .details-box {
          .line-1 {
            .icon-set {
              padding: 0 10px;
              img {
                width: 36px;
                margin: auto 5px;

                @include respond-to("medium_lg") {
                  width: 30px;
                  margin-right: 5px;
                }
                @include respond-to("medium") {
                  width: 24px;
                  margin-right: 1px;
                }
              }
            }
          }
          min-height: 240px;
          @include respond-to("ex_large_max") {
            min-height: 270px;
          }
          .line-2 {
            .title {
              font-size: 14px;
              color: #000000;
            }

            .desc {
              font-size: 12px;
              visibility: visible;
              opacity: 1;
              padding-bottom: 20px;
              span {
                a {
                  display: block;
                }
              }
              @include respond-to("ex_large_max") {
                font-size: 12px;
              }
              @include respond-to("medium_lg") {
                font-size: 10px;
                margin-top: 5px;
              }
            }
          }
        }
      }
    }
  }
  //------------------------our team Mobile view--------------------------------
  .principles {
    display: none;
    padding: 40px 18px;
    @include respond-to("small") {
      display: block;
    }
    .card_containar {
      margin: 10px;
      box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.16);
      transition: 0.3s;
      margin: 10px 10px 20px 10px;
      background-color: #ffffff;
      border-radius: 12px;
      overflow: hidden;
      .leftside_text {
        display: flex;
        align-items: center;
        overflow: hidden;
        .image_containar {
          //width: 156px;
          filter: grayscale(1);
        }
        .image_containar-color {
          display: none;
        }
        .card_list_items {
          padding: 0px 10px;
          object-fit: contain;
          font-family: Poppins;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.5;
          letter-spacing: normal;
          text-align: left;
          color: #000000;
          position: relative;
          .arrow {
            margin: 10px 0 auto 110px;
            display: none;
          }
          .name {
            opacity: 0.6;
            font-size: 14px;
            font-weight: 600;
            line-height: 1.5;
            letter-spacing: normal;
            text-align: left;
            color: #000000;
          }
          .title {
            opacity: 0.6;
            font-size: 12px;
            font-weight: 300;
            line-height: 1.5;
            letter-spacing: normal;
            text-align: left;
            color: #000000;
            margin-bottom: 15px;
          }
          .icon-set {
            //padding: 0 10px;
            @include respond-to("small") {
              position: absolute;
              bottom: -30px;
            }
            img {
              width: 32px;
              margin-right: 12px;
              @include respond-to("small") {
                width: 34px;
                margin-right: 10px;
              }
            }
          }
        }
      }

      .desc {
        display: none;
        opacity: 0.7;
        font-size: 12px;
        font-weight: 300;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: left;
        color: #000000;
        padding: 12px;
        text-align: justify;
      }
    }
    .team-card {
      &.active {
        animation: fade-in-keyframes 0.3s ease-in-out;
        @keyframes fade-in-keyframes {
          from {
            opacity: 0;
          }
          to {
            opacity: 1;
          }
        }
        .leftside_text {
          .image_containar {
            transform: scale(1);
            padding: 10px;
            filter: grayscale(0);
          }
        }
        .card_list_items {
          .arrow {
            display: block;
            @include respond-to("small") {
              display: none;
            }
          }
        }
        .desc {
          display: block;
        }
      }
    }
  }
}
//----------------Investment Categories section ----------------------------------
.inv-category-wrapper {
  background-color: #f9fafb;
  // padding: 140px 0 50px;
  padding: 80px 0 0;
  @include respond-to("small") {
    padding: 50px 0 50px;
  }
  .section-title {
    text-align: center;
    width: 40%;
    margin: 0 auto;
    p {
      text-align: center;
      @include respond-to("small") {
        padding: 0 12px 0;
      }
    }

    h2 {
      color: #484848;
      font-size: 28px;
      line-height: 1.5;
      @include respond-to("small") {
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 20px;
      }
    }
    p {
      color: #000000;
      font-size: 18px;
      font-weight: 300;
      opacity: 0.6;
      @include respond-to("small") {
        font-size: 12px;
        font-weight: 300;
      }
    }
    @include respond-to("medium") {
      width: 80%;
    }
  }
  .inv-category {
    display: flex;
    justify-content: center;
    // margin: 50px 0 0;
    // padding: 46px 180px;
    padding: 80px 180px;
    flex-flow: row wrap;
    @include respond-to("medium_lg") {
      padding: 0;
    }
    @include respond-to("medium") {
      padding: 0;
    }
    @include respond-to("small") {
      display: block;
      padding: 0;
    }
    .card {
      text-decoration: none;
      width: 24%;
      border-radius: 8px;
      box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.16);
      background-color: #ffffff;
      margin: 10px;
      transition: transform 0.5s;
      @include respond-to("small") {
        display: flex;
        width: 90%;
        height: 170px;
        margin: 20px;
        border-radius: 12px;
      }

      .card_img {
        overflow: hidden;
        border: 15px solid #ffffff;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        @include respond-to("medium") {
          border: 6px solid #ffffff;
        }
        @include respond-to("small") {
          border: 10px solid #ffffff;
          height: 150px;
          border-radius: 12px;
        }
        img {
          border-radius: 8px;
          transition: transform 0.5s;
          width: 100%;
          @include respond-to("small") {
            height: 150px;
            width: 150px;
            object-fit: cover;
            object-position: top;
          }
        }
      }
      &:hover {
        transform: scale(1.05);
        img {
          transform: scale(1.2);
        }
      }
      .cardInfo {
        padding-bottom: 15px;
        h2 {
          text-align: center;
          color: #000000;
          line-height: 1.5;
          opacity: 0.8;
          font-family: Poppins;
          font-size: 14px;
          font-weight: 600;
          @include respond-to("medium") {
            font-size: 12px;
            padding: 10px;
            //padding: 69px 0;
          }
          @include respond-to("small") {
            font-size: 12px;
            padding: 10px;
            padding: 69px 10px;
            font-size: 14px;
            font-weight: 600;
            text-align: left;
          }
        }
      }
    }
  }
}
//-------- Home page contact section ----------------------------
.home-contact-wrapper {
  position: relative;
  .contact-img {
    width: 100%;
    object-fit: cover;
    display: block;
    @include respond-to("small") {
      display: none;
    }
  }
  .contact-img-mobile {
    display: none;
    @include respond-to("small") {
      display: block;
      width: 100%;
    }
  }
  .contact {
    position: absolute;
    top: 15%;
    left: 12%;
    width: 30%;
    @include respond-to("medium_lg") {
      width: 36%;
    }
    @include respond-to("medium") {
      width: 41%;
      left: 10%;
    }
    @include respond-to("small") {
      width: 70%;
      top: 10%;
    }
    .content {
      h2 {
        font-size: 36px;
        font-weight: 600;
        color: #000000;
        opacity: 0.7;
        margin-bottom: 10px;
        @include respond-to("medium_lg") {
          margin-bottom: 6px;
        }
        @include respond-to("small") {
          font-size: 28px;
          font-weight: 600;
        }
      }
      p {
        font-size: 14px;
        font-weight: 300;
        opacity: 0.6;
        color: #000000;
        @include respond-to("small") {
          font-size: 12px;
          font-weight: 300;
        }
      }
      ul{
        list-style: none;
        li{
          color: #484848;
          font-size: 16px;
          margin: 8px 0;
          @include respond-to("small"){
            font-size: 14px;
          }
        }
      }
      button {
        border-radius: 24px;
        box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
        background-color: #8eae3f;
        font-size: 20px;
        font-weight: 600;
        color: #ffffff;
        padding: 15px 10px;
        width: 250px;
        border: none;
        margin-top: 60px;
        outline: none;
        cursor: pointer;
        &:active {
          box-shadow: 0 2px #666;
          transform: scale(0.98);
        }
        &:hover {
          opacity: 0.9;
        }

        @include respond-to("medium_lg") {
          margin-top: 30px;
        }
        @include respond-to("small") {
          font-size: 16px;
          width: 175px;
        }
      }
    }
  }
}


.quotes-wrapper{
  background: #ffffff;
  padding: 80px 0 120px;
  @include respond-to("small"){
    padding: 50px 0 70px;
  }
  .qt-slider{
    width: 60%;
    margin: 0 auto;
    justify-content: center;
    align-items: center;
    text-align: center;
    @include respond-to("medium"){
      width: 80%;
    }
    @include respond-to("small"){
      width: 90%;
    }
    .section-title {
      text-align: center;
      margin: 0 auto;
      @include respond-to("medium") {
        width: 100%;
      }
      h2{
        color: #484848;
        font-size: 32px;
        font-weight: 600;
        margin-bottom: 32px;
        @include  respond-to("small"){
          font-size: 18px;
        }
      }
    }
  .slider-card{
    .quote-text{
      p{
        color: #484848;
        text-align: center;
        font-style: italic;
        font-size: 20px;
        font-weight: 400;
        @include respond-to("small"){
          font-size: 12px;
          font-weight: 300;
        }
      }
    }
    .quote-footer{
      padding: 24px 0;
      p{
        color: #484848;
        text-align: center;
        font-size: 16px;
        font-weight: 400;
        @include respond-to("small"){
          font-size: 12px;
          font-weight: 300;
        }
      }
    }
  }
  }
}
.slick-dots li button:before {
  opacity: 0.25;
  }
  .slick-dots li button:focus:before {
  opacity: 0.25 !important;
  }
  .slick-dots li.slick-active button:focus:before {
  opacity: 0.75 !important;
  }
  .slick-dots li.slick-active button:before {
  opacity: 0.75 !important;
  }