// =========================================
// ====== About and Contact styles =========
// =========================================

//------------about wrapper and contact wrapper-------------
//************Both elements are the same********************
.page-block-about{
  display: flex;
  flex-direction: row;
  @include respond-to("medium_lg"){
    flex-direction: column;
  }
}
.about-us-wrapper,
.contact-us-wrapper {
  .content-wrapper {
    width: 100%;
    padding-top: 20px;
    .content-inner-box {
      width: 64%;
      margin: 0 auto;
      @include respond-to("medium_lg"){
        width: 86%;
      }

      @include respond-to("small") {
        width: 100%;
      }
      .about-us-text {
        font-size: 14px;
        font-weight: 300;
        line-height: 1.5;
        padding: 0 100px 40px;
        // margin-bottom: 70px;
        @include respond-to("medium") {
          padding: 0 0 28px;
        }
        @include respond-to("small") {
          margin: 0% 7% 10%;
          padding: 0;
        }
      }
      .company-values {
        margin-bottom: 50px;
        @include respond-to("small") {
          margin-bottom: 0px;
        }
        .values-title{
          // padding: 28px 100px;
          padding: 0px 0px 20px;
          @include respond-to("small") {
            padding: 12px 0px;
          }
          h2{
            font-size: 28px;
            font-weight: 600;
            letter-spacing: 1px;
            color:$primary-color;
            @include respond-to("small") {
              font-size: 28px;
              margin: 0px 7%;
            }
          }
        }
        .value-text {
          // padding: 0px 100px;
          @include respond-to("small") {
            padding: 20px 0px;
            width: 100%;
          }
          p {
            font-size: 14px;
            font-weight: 300;
            line-height: 1.5;
            color: #000000;
            // padding: 0 80px;
            @include respond-to("medium") {
              // padding: 0 15px;
            }
            @include respond-to("small") {
              // padding: 0;
              margin: auto 7%;
            }
          }
          h3 {
            color: #646466;
            opacity: 0.9;
            font-size: 24px;
            font-weight: 600;
            line-height: 2;
            letter-spacing: 0.86px;
            padding-bottom: 30px;
            // padding: 30px 100px 8px;
            @include respond-to("medium_lg") {
              // padding: 30px 26px 8px;
            }
            @include respond-to("medium") {
              line-height: 1;
              // padding: 30px 30px 8px;
              font-size: 12px;
              text-align: left;
            }
            @include respond-to("small") {
              font-size: 15px;
              text-align: left;
            }
          }
        }
        .principles {
            background: rgba(142, 174, 63, 0.15);
            padding: 40px 80px;
            margin: 40px 0px;
            @include respond-to("medium_lg") {
              padding: 40px 60px;
            }
            @include respond-to("medium") {
              padding: 40px;
            }
            @include respond-to("small") {
              padding: 40px 14px;
              margin: 0px 0px;

            }
          .card_containar {
            box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
            transition: 0.3s;
            // margin: 10px 10px 20px 10px;
            margin: 10px 0px;
            /* width: 100%; */
            /* height:156px; */
            background-color: #ffffff;
            border-top-right-radius: 8px;
            border-bottom-right-radius: 8px;
            border-top-left-radius: 8px;
            border-bottom-left-radius: 8px;
            overflow: hidden;
            .leftside_text {
              display: flex;
              align-items: center;
              @include respond-to("small") {
                //display: block;
              }
              .card_list_items {
                padding: 14px 75px;
                object-fit: contain;
                font-family: Poppins;
                font-size: 14px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.5;
                letter-spacing: normal;
                text-align: left;
                color: #000000;
                @include respond-to("medium") {
                  padding: 14px 20px 14px 40px;
                }
                @include respond-to("small") {
                  font-size: 10px;
                  padding: 0 10px 0 20px;
                }
                ul {
                  li {
                    margin-bottom: 10px;
                    font-family: "Poppins", sans-serif;
                    &:last-child {
                      margin-bottom: 0;
                    }
                  }
                }
                &.contact-us-item {
                  font-size: 18px;
                  padding: 0 75px;
                  @include respond-to("medium") {
                    font-size: 14px;
                    padding: 10px 30px;
                  }
                  @include respond-to("small") {
                    padding: 6px 8px 6px 21px;
                    font-size: 10px;
                  }
                }
              }
              .image_containar {
                // width: 200px;
                // height: 180px;
                position: relative;
                margin-left: -3px;
                @include respond-to("small") {
                  width: 30%;
                  overflow: hidden;
                  height: 109px;
                }
                .image {
                  z-index: 99;
                  display: flex;
                  @include respond-to("medium") {
                    width: 180px;
                    height: 165px;
                    object-fit: cover;
                  }
                  @include respond-to("small") {
                    margin-left: 0;
                    width: 100%;
                    height: 114px;
                    width: 100%;
                    object-position: top;
                    object-fit: cover;
                    align-items: flex-start;
                    position: absolute;
                    bottom: -4px;
                  }
                }
                .image_icon {
                  position: absolute;
                  top: 38%;
                  left: 0;
                  right: 0;
                  margin: 0 auto;
                  z-index: 99;
                  @include respond-to("small") {
                    margin-left: 0;
                    margin: 0 auto;
                    top: 25%;
                  }
                }
                h2 {
                  position: absolute;
                  font-family: Poppins;
                  font-size: 16px;
                  font-weight: 600;
                  letter-spacing: 0.58px;
                  text-align: center;
                  color: #ffffff;
                  top: 60%;
                  left: 0;
                  right: 0;
                  margin: 0 auto;
                  z-index: 99;
                  @include respond-to("small") {
                    margin: auto 1px;
                    font-size: 10px;
                    letter-spacing: 0.58px;
                    //margin-left: 0;
                  }
                }
              }

              .card_list_items {
                width: 50%;
                @include respond-to("small") {
                  width: 77%;
                }
              }
              &.contact_leftside_text {
                display: flex;

                @include respond-to("small") {
                  max-height: 100px;
                  overflow: hidden;
                }
                .image_containar {
                  width: 23%;
                  @include respond-to("medium") {
                    width: 24%;
                  }
                  @include respond-to("small") {
                    border-top-right-radius: 0;
                    border-bottom-left-radius: 8px;
                    //height: 100%;
                    width: 35%;
                    object-fit: cover;
                    object-position: top;
                    overflow: hidden;
                  }
                }
              }
            }
            &:hover {
              box-shadow: 0 0 24px 12px #d6d6d6;
              transform: scale(1.05);
            }
          }
        }
      }
    }
    .team-profile-wrapper {
      .profile-main-content {
        .discription_block_team {
          width: 64%;
          margin: 0 auto;
          padding: 12px 0;
          @include respond-to("large"){
            width: 70%;
          }
          @include respond-to("medium_lg"){
            width: 80%;
          }
          @include respond-to("small") {
            padding: 12px 0;
            width: 92%;
          }
          .card_containar_team {
            box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
            transition: 0.3s;
            width: 100%;
            /* height:156px; */
            background-color: #ffffff;
            border-top-right-radius: 8px;
            border-bottom-right-radius: 8px;
            border-top-left-radius: 8px;
            border-bottom-left-radius: 8px;
            &:hover {
              box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
            }
            .leftside_text_team {
              display: grid;
              grid-template-columns: 0fr 1fr;
              .image_containar_team {
                height: 250px;
                padding: 10px;
                border-radius: 18px;
                filter: grayscale(100%);
                @include respond-to("large") {
                  height: 200px;
                }
                @include respond-to("medium") {
                  height: 200px;
                }
                @include respond-to("small") {
                  height: 150px;
                }
              }
            }
            .rightside_containar {
              position: relative;
              .name_label {
                margin: 20px 0px 0px -60px;
                position: relative;
                width: 250px;
                border-radius: 8px;
                box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.16);
                background-image: linear-gradient(103deg, #97df79 0%, #7bce76 69%);
                @include respond-to("medium") {
                  margin-top: 40px;
                }
                @include respond-to("small") {
                  width: 180px;
                  margin-top: 30px;
                  margin-left: -38px;
                }
                img {
                  width: 100%;
                }
                p {
                  color: #ffffff;
                  font-size: 18px;
                  font-weight: 600;
                  position: absolute;
                  top: 15px;
                  left: 30px;
                  z-index: 999;
                  display: flex;
                  flex-direction: column;
                  line-height: 20px;
                  @include respond-to("small") {
                    top: 8px;
                    left: 15px;
                    font-size: 14px;
                    line-height: 16px;
                  }
                  span {
                    font-size: 14px;
                    font-weight: normal;
                    @include respond-to("small") {
                      font-size: 10px;
                    }
                  }
                }
              }
              .short-desc {
                font-size: 14px;
                padding: 25px;
                width: 80%;
                @include respond-to("large") {
                  font-size: 12px;
                  padding: 16px;
                }
                @include respond-to("medium") {
                  display: none;
                }
              }
              .header_icons {
                display: none;
                @include respond-to("medium") {
                  display: block;
                  margin-top: 20px;
                  a {
                    margin-right: 10px;
                  }
                }
              }
              .arrow_btn {
                position: absolute;
                bottom: 25px;
                right: 25px;
                cursor: pointer;
                @include respond-to("small") {
                  bottom: 10px;
                  right: 15px;
                }
              }
            }
          }
        }
        .profile-lg-card {
          width: 100%;
          .profilr-lg-inner {
            width: 68%;
            margin: 30px auto 50px;
            @include respond-to("medium_lg"){
              width: 80%;
            }
            @include respond-to("small") {
              width: 100%;
            }
            .card_content_wrapper {
              border-color: #ffffff;
              border-style: solid;
              border-width: 1px;
              box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.16);
              .cover-img {
                width: 100%;
                height: 275px;
                object-fit: cover;
                object-position: 0 0;
                background-color: gray;
                @include respond-to("small") {
                  height: 160px;
                  object-fit: cover;
                  object-position: center;
                }
              }
              .card_content-col {
                display: flex;
                @include respond-to("medium") {
                  display: block;
                }
                .image_cap {
                  width: 20%;
                  background-color: #ffffff;
                  margin: -90px 25px 40px;
                  padding: 12px;
                  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.16);
                  border-radius: 6px;
                  position: relative;
                  z-index: 99;
                  @include respond-to("medium") {
                    width: 86%;
                    margin: -100px auto 0;
                    padding: 2%;
                  }
                  .profile_discription {
                    @include respond-to("medium") {
                      display: flex;
                    }
                    img {
                      width: 100%;
                      object-fit: cover;
                      border-radius: 8px;
                      object-position: 0 0;
                      @include respond-to("medium") {
                        width: 200px;
                        height: 200px;
                        margin-right: 15px;
                      }
                      @include respond-to("small") {
                        width: 30%;
                        height: 30%;
                        margin-right: 15px;
                      }
                    }
                    .profile-text-01 {
                      font-size: 14px;
                      font-weight: 300;
                      line-height: 1.5;
                      margin-top: 10px;
                      text-align: left;
                      @include respond-to("small") {
                        font-size: 12px;
                      }
                    }
                  }
                }
                .profile_header {
                  width: 70%;
                  background-color: #ffffff;
                  padding: 20px 0 0 15px;
                  @include respond-to("medium") {
                    width: 88%;
                    padding: 15px 6%;
                    margin-top: 30px;
                  }
                  .header_tagline {
                    display: flex;
                    .subheding_text {
                      margin-right: 6px;
                      width: 30%;
                      margin-bottom: 20px;
                      @include respond-to("medium") {
                        width: 68%;
                      }
                      @include respond-to("small") {
                        width: 100%;
                        h2 {
                          font-size: 14;
                        }
                        h5 {
                          font-size: 14px;
                          font-weight: normal;
                        }
                      }
                    }
                    .header_icons {
                      display: flex;
                      width: 69%;
                      align-items: center;
                      //padding-right: 50px;
                      position: relative;
                      @include respond-to("small") {
                        width: auto;
                      }
                      a {
                        //margin-right: 14px;
                        margin-left: 20px;

                        img {
                          @include respond-to("small") {
                            display: none;
                          }
                          &.arrow {
                            position: absolute;
                            right: 12px;
                            top: 30px;
                            cursor: pointer;
                            @include respond-to("medium_lg") {
                              right: 15px;
                            }
                            @include respond-to("small") {
                              display: block;
                              top: 0;
                            }
                          }
                        }
                        &:first-child {
                          //cursor: pointer;
                        }
                      }
                    }
                  }
                  .main-desc {
                    width: 85%;
                    font-size: 14px;
                    font-weight: 300;
                    line-height: 1.5;
                    padding-bottom: 100px;
                    @include respond-to("small") {
                      width: 100%;
                      font-size: 13px;
                      padding-bottom: 0;
                    }
                    &.one {
                      padding-bottom: 10px;
                    }
                  }
                }
              }
            }
          }
        }

        &.active {
          .discription_block_team {
            display: none;
          }
          //-----This is only Used for webb knudsen see more details cover page position-----------
          &.index4 {
            .profile-lg-card .profilr-lg-inner .card_content_wrapper .cover-img {
              @include respond-to("small") {
                object-position: left;
              }
            }
          }
        }
      }

      .profile-main-content-flex {
        display: flex;
        width: 64%;
        margin: 0 auto;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
        border-radius: 8px;
        // padding: 12px 0;
        @include respond-to("large"){
          width: 70%;
        }
        @include respond-to("medium_lg"){
          width: 80%;
        }
        @include respond-to("small") {
          width: 92%;
          flex-direction: column;
        }
        .discription_block_team {
          width: 100%; // make this 50% when kris profile add

          @include respond-to("small") {
            width: 92%;
          }
          .card_containar_team {
            transition: 0.3s;
            width: 100%;
            .leftside_text_team {
              display: grid;
              grid-template-columns: 0fr 1fr;
              .image_containar_team {
                height: 250px;
                padding: 10px;
                border-radius: 18px;
                @include respond-to("large") {
                  height: 200px;
                }
                @include respond-to("small") {
                  height: 150px;
                }
              }
            }
            .rightside_containar {
              position: relative;
              .name_label {
                margin: 20px 0px 0px -60px;
                position: relative;
                width: 220px;
                border-radius: 8px;
                box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.16);
                background-image: linear-gradient(103deg, #97df79 0%, #7bce76 69%);
                @include respond-to("large"){
                  width: 200px;
                }
                @include respond-to("medium_lg"){
                  width: 150px;
                  height: 64px;

                }
                @include respond-to("medium") {
                  margin-top: 40px;
                }
                @include respond-to("small") {
                  width: 180px;
                  margin-top: 30px;
                  margin-left: -38px;
                  height: auto;

                }
                img {
                  width: 100%;
                }
                p {
                  color: #ffffff;
                  font-size: 18px;
                  font-weight: 600;
                  position: absolute;
                  top: 15px;
                  left: 30px;
                  z-index: 999;
                  display: flex;
                  flex-direction: column;
                  line-height: 20px;
                  @include respond-to("small") {
                    top: 8px;
                    left: 15px;
                    font-size: 14px;
                    line-height: 16px;
                  }
                  span {
                    font-size: 14px;
                    font-weight: normal;
                    @include respond-to("small") {
                      font-size: 10px;
                    }
                  }
                }
              }
              .short-desc {
                font-size: 14px;
                padding: 25px;
                width: 80%;
                @include respond-to("large") {
                  font-size: 12px;
                  padding: 16px;
                }
                @include respond-to("medium") {
                  display: none;
                }
              }
              .header_icons {
                display: none;
                @include respond-to("medium") {
                  display: block;
                  margin-top: 10px;
                  a {
                    margin-right: 10px;
                  }
                }
              }
              .arrow_btn {
                position: absolute;
                bottom: 25px;
                right: 25px;
                cursor: pointer;
                @include respond-to("small") {
                  bottom: 10px;
                  right: 15px;
                }
              }
            }
          }
        }
        .profile-lg-card {
          width: 100%;
          .profilr-lg-inner {
            width: 100%;
            margin: 30px auto 50px;
            @include respond-to("medium_lg"){
              width: 80%;
            }
            @include respond-to("small") {
              width: 100%;
            }
            .card_content_wrapper {
              border-color: #ffffff;
              border-style: solid;
              border-width: 1px;
              // box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.16);
              .cover-img {
                width: 100%;
                height: 275px;
                object-fit: cover;
                object-position: 0 0;
                background-color: gray;
                @include respond-to("small") {
                  height: 160px;
                  object-fit: cover;
                  object-position: center;
                }
              }
              .card_content-col {
                display: flex;
                @include respond-to("medium") {
                  display: block;
                }
                .image_cap {
                  width: 20%;
                  background-color: #ffffff;
                  margin: -90px 25px 40px;
                  padding: 12px;
                  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.16);
                  border-radius: 6px;
                  position: relative;
                  z-index: 99;
                  @include respond-to("medium") {
                    width: 86%;
                    margin: -100px auto 0;
                    padding: 2%;
                  }
                  .profile_discription {
                    @include respond-to("medium") {
                      display: flex;
                    }
                    img {
                      width: 100%;
                      object-fit: cover;
                      border-radius: 8px;
                      object-position: 0 0;
                      @include respond-to("medium") {
                        width: 200px;
                        height: 200px;
                        margin-right: 15px;
                      }
                      @include respond-to("small") {
                        width: 30%;
                        height: 30%;
                        margin-right: 15px;
                      }
                    }
                    .profile-text-01 {
                      font-size: 14px;
                      font-weight: 300;
                      line-height: 1.5;
                      margin-top: 10px;
                      @include respond-to("small") {
                        font-size: 12px;
                      }
                    }
                  }
                }
                .profile_header {
                  width: 70%;
                  background-color: #ffffff;
                  padding: 20px 0 0 15px;
                  @include respond-to("medium") {
                    width: 88%;
                    padding: 15px 6%;
                    margin-top: 30px;
                  }
                  .header_tagline {
                    display: flex;
                    .subheding_text {
                      margin-right: 6px;
                      width: 30%;
                      margin-bottom: 20px;
                      @include respond-to("medium") {
                        width: 68%;
                      }
                      @include respond-to("small") {
                        width: 100%;
                        h2 {
                          font-size: 14;
                        }
                        h5 {
                          font-size: 14px;
                          font-weight: normal;
                        }
                      }
                    }
                    .header_icons {
                      display: flex;
                      width: 69%;
                      align-items: center;
                      //padding-right: 50px;
                      position: relative;
                      @include respond-to("small") {
                        width: auto;
                      }
                      a {
                        //margin-right: 14px;
                        margin-left: 20px;

                        img {
                          @include respond-to("small") {
                            display: none;
                          }
                          &.arrow {
                            position: absolute;
                            right: 12px;
                            top: 30px;
                            cursor: pointer;
                            @include respond-to("medium_lg") {
                              right: 15px;
                            }
                            @include respond-to("small") {
                              display: block;
                              top: 0;
                            }
                          }
                        }
                        &:first-child {
                          //cursor: pointer;
                        }
                      }
                    }
                  }
                  .main-desc {
                    width: 85%;
                    font-size: 14px;
                    font-weight: 300;
                    line-height: 1.5;
                    padding-bottom: 100px;
                    @include respond-to("small") {
                      width: 100%;
                      font-size: 13px;
                      padding-bottom: 0;
                    }
                    &.one {
                      padding-bottom: 10px;
                    }
                  }
                }
              }
            }
          }
        }

        &.active {
          width: 72%;
          @include respond-to("small"){
            width: 100%;
          }
          .discription_block_team {
            display: none;
          }
          //-----This is only Used for webb knudsen see more details cover page position-----------
          // &.index4 {
          //   .profile-lg-card .profilr-lg-inner .card_content_wrapper .cover-img {
          //     @include respond-to("small") {
          //       object-position: left;
          //     }
          //   }
          // }
        }
      }
    }
    //-------------------------pdf file download section styles-------------------------
    &.learn-more-about {
      // margin-top: 150px;
      @include respond-to("small") {
        margin-top: 50px;
      }
      .content-inner-box {
        .company-values {
          .value-text {
            .principles {
              background: rgba(142, 174, 63, 0.15);
              padding: 40px 80px;
              margin: 40px 0px;
              @include respond-to("medium_lg") {
                padding: 40px 60px;
              }
              @include respond-to("medium") {
                padding: 40px;
              }
              @include respond-to("small") {
                padding: 40px 14px;
                margin: 0px 0px;

              }
              .card_containar {
                &:hover {
                  // box-shadow: none;
                  box-shadow: 0 0 24px 12px #d6d6d6;
                  transform: scale(1.05);
                }
                .leftside_text {
                  display: flex;
                  align-items: center;
                  overflow: hidden;
                  height: 112px;
                  @include respond-to("medium") {
                    height: 100%;  
                  }
                  @include respond-to("small") {
                    height: 100%;  
                  }
                  .download_img {
                    position: relative;
                    //width: 33%;
                    .image_containar {
                      width: 100%;
                      //height: 100%;
                      height: 150px;
                      overflow: hidden;
                      display: block;
                      @include respond-to("medium") {
                        width: 100%;
                        height: 154px;
                        display: flex;
                        overflow: hidden;
                      }
                      @include respond-to("small") {
                        width: 100%;
                        height: 112px;
                        display: flex;
                        overflow: hidden;
                      }
                    }
                    .download {
                      position: absolute;
                      top: 42%;
                      left: 0;
                      right: 0;
                      margin: 0 auto;
                      transition: 0.3s;
                      @include respond-to("small") {
                        //top: 35%;
                        right: 1%;
                      }
                    }
                  }
                  .card_list_items {
                    ul {
                      li {
                        margin: 0;
                        font-weight: 600;
                      }
                    }
                  }
                }
                &:hover {
                  .download {
                    transform: scale(1.3);
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
.padding-para{
  padding-bottom: 12px;
}