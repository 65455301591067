// =========================================
// ======= portfolio page styles ===========
// =========================================
.page-block-portfolio{
  display: block;
  flex-direction: row;
  @include respond-to("medium_lg"){
    flex-direction: column;
  }
}
.portfoilo-wrapper {
    .content-wrapper {
      width: 100%;
      .content-inner-box {
        width: 64%;
        margin: 0 auto;
        padding-top: 20px;
        @include respond-to("medium_lg"){
          width: 86%;
        }
        @include respond-to("small") {
          width: 100%;
        }
        .company-values{
          margin-bottom: 50px;
          @include respond-to("small") {
            margin-bottom: 0px;
          }
          .values-title{
              // padding: 28px 100px;
              padding: 0px 0px 20px;
              @include respond-to("small") {
                padding: 12px 0px;
              }
              h2{
                font-size: 28px;
                font-weight: 600;
                letter-spacing: 1px;
                color:$primary-color;
                @include respond-to("small") {
                  font-size: 28px;
                  margin: 0px 7%;
                }
              }
              h3{
                font-size: 28px;
                font-weight: 600;
                letter-spacing: 1px;
                color:$dark;
                @include respond-to("small") {
                  font-size: 18px;
                  margin: 0px 7%;
                }
              }
            }
            .value-text {
              // padding: 0px 100px;
              @include respond-to("small") {
                padding: 20px 0px;
                width: 100%;
              }
              p {
                font-size: 14px;
                font-weight: 300;
                line-height: 1.5;
                color: #000000;
                // padding: 0 80px;
                @include respond-to("medium") {
                  // padding: 0 15px;
                }
                @include respond-to("small") {
                  padding: 0;
                  margin: auto 7%;
                }
              }
              h3 {
                color: #646466;
                opacity: 0.9;
                font-size: 24px;
                font-weight: 600;
                line-height: 2;
                letter-spacing: 0.86px;
                // padding: 30px 100px 8px;
                @include respond-to("medium_lg") {
                  padding: 30px 26px 8px;
                }
                @include respond-to("medium") {
                  line-height: 1;
                  padding: 30px 30px 8px;
                  font-size: 12px;
                  text-align: left;
                }
                @include respond-to("small") {
                  font-size: 15px;
                  text-align: left;
                }
              }
            }
      }
        .about-us-text {
          font-size: 14px;
          font-weight: 300;
          line-height: 1.5;
          padding: 0 132px;
          // margin-bottom: 70px;
          @include respond-to("medium") {
            padding: 0;
          }
          @include respond-to("small") {
            margin: 0% 7% 10%;
            padding: 0;
          }
        }
        .company-values {
          .principles {
            background: rgba(142, 174, 63, 0.15);
            padding: 40px 80px;
            margin: 40px 0px;
            @include respond-to("medium_lg") {
              padding: 40px 60px;
            }
            @include respond-to("medium") {
              padding: 40px;
            }
            @include respond-to("small") {
              padding: 40px 14px;
              margin: 0px 0px;
            }
            .card_containar {
              box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
              transition: 0.3s;
              margin: 10px 10px 20px 10px;
              /* width: 100%; */
              /* height:156px; */
              background-color: #ffffff;
              border-top-right-radius: 8px;
              border-bottom-right-radius: 8px;
              border-top-left-radius: 8px;
              border-bottom-left-radius: 8px;
              overflow: hidden;
              .leftside_text {
                display: flex;
                align-items: center;
                overflow: hidden;
                @include respond-to("small") {
                  //display: block;
                }
  
                .card_list_items {
                  padding: 14px 75px;
                  object-fit: contain;
                  font-family: Poppins;
                  font-size: 14px;
                  font-weight: normal;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: 1.5;
                  letter-spacing: normal;
                  text-align: left;
                  color: #000000;
                  @include respond-to("medium_lg") {
                    padding: 14px 20px 14px 40px;
                  }
                  @include respond-to("medium") {
                    padding: 14px 20px 14px 40px;
                  }
                  @include respond-to("small") {
                    width: 77%;
                    font-size: 10px;
                    padding: 0 10px 0 20px;
                  }
                  ul {
                    li {
                      margin-bottom: 10px;
                      &:last-child {
                        margin-bottom: 0;
                      }
                    }
                  }
                  &.contact-us-item {
                    font-size: 18px;
                    padding: 0 75px;
                    @include respond-to("small") {
                      padding: 6px 8px 6px 21px;
                      font-size: 10px;
                    }
                  }
                }
                .image_containar {
                  // width: 156px;
                  position: relative;
                  // margin-left: -3px;
                  @include respond-to("medium") {
                    // width: 140px;
                    // height: 154px;
                    // object-fit: cover;
                    // object-position: center;
                  }
                  @include respond-to("small") {
                    width: 30%;
                    overflow: hidden;
                    height: 109px;
                    display: flex;
                    align-items: center;
                    align-content: center;
                  }
  
                  .image {
                    z-index: 99;
                    display: flex;
                    @include respond-to("medium") {
                      width: 180px;
                      height: 168px;
                      object-fit: cover;
                      right: 1px;
                    }
                    @include respond-to("small") {
                      height: 113px;
                      margin-left: 0;
                      width: 100%;
                      object-position: top;
                      object-fit: cover;
                      //align-items: flex-start;
                      position: absolute;
                      bottom: -4px;
                      top: -1px;
                    }
                  }
  
                  .image_icon {
                    position: absolute;
                    top: 35%;
                    transform: scale(0.8);
                    left: 0;
                    right: 0;
                    margin: 0 auto;
                    z-index: 99;
                    @include respond-to("small") {
                      position: relative;
                      margin-left: 0;
                      margin: 0 auto;
                      top: 0;
                    }
                  }
                }
              }
              &:hover {
                box-shadow: 0px 0px 24px 12px rgba(214, 214, 214, 1);
                transform: scale(1.05);
              }
            }
          }
        }
        .card-investments {
          background: rgba(142, 174, 63, 0.15);
          padding: 3% 6%;
  
          margin: 4% auto 4% auto;
          .card-wrapper {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            .logo-box {
              width: 20%;
              // box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.06);
              text-align: center;
              justify-content: space-between;
              transition: 0.3s;
              @include respond-to("small") {
                width: 33.33%;
              }
              .small-text{
                min-height: 18px;
                font-size: 8px;
                text-align: center;
                color: #484848;
              }
              .logo-img-box {
                width: 75%;
                margin: 0 auto;
                background-color: #ffffff;
                border-radius: 16px;
                padding: 10%;
                margin-bottom: 10px;
                img {
                  width: 100%;
                }
                &:hover {
                  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
                  cursor: pointer;
                }
              }
            }
          }
          .ref-word{
            text-align: right;
            font-size: 12px;
            color: #484848;
          }
        }
      }
      .team-profile-wrapper {
        .profile-main-content {
          transition: visibility;
          &.active {
            .discription_block_team {
              display: none;
            }
            //visibility: hidden;
            .profile-lg-card {
              visibility: visible;
            }
          }
          &.none-active {
            display: block;
            .profile-lg-card {
              //display: none;
            }
          }
          .discription_block_team {
            width: 70%;
            margin: 0 auto;
            padding: 0 100px;
            .card_containar_team {
              box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
              transition: 0.3s;
              width: 100%;
              /* height:156px; */
              background-color: #ffffff;
              border-top-right-radius: 8px;
              border-bottom-right-radius: 8px;
              border-top-left-radius: 8px;
              border-bottom-left-radius: 8px;
              &:hover {
                box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
              }
              .leftside_text_team {
                display: grid;
                grid-template-columns: 0fr 1fr;
                .image_containar_team {
                  height: 250px;
                  padding: 10px;
                }
              }
              .rightside_containar {
                position: relative;
                .name_label {
                  margin: 20px 0px 0px -60px;
                  position: relative;
                  width: 250px;
                  border-radius: 8px;
                  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.16);
                  background-image: linear-gradient(103deg, #97df79 0%, #7bce76 69%);
                  img {
                    width: 100%;
                  }
                  p {
                    color: #ffffff;
                    font-size: 18px;
                    font-weight: 600;
                    position: absolute;
                    top: 15px;
                    left: 30px;
                    z-index: 999;
                    display: flex;
                    flex-direction: column;
                    line-height: 20px;
                    span {
                      font-size: 14px;
                      font-weight: normal;
                    }
                  }
                }
                .short-desc {
                  font-size: 14px;
                  padding: 25px;
                  width: 80%;
                }
                .arrow_btn {
                  position: absolute;
                  bottom: 25px;
                  right: 25px;
                  cursor: pointer;
                }
              }
            }
          }
          .profile-lg-card {
            width: 100%;
            .profilr-lg-inner {
              width: 80%;
              margin: 30px auto 50px;
              .card_content_wrapper {
                border-color: #ffffff;
                border-style: solid;
                border-width: 1px;
                box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.16);
                .cover-img {
                  width: 100%;
                  height: 275px;
                  object-fit: cover;
                  object-position: 0 0;
                }
                .card_content-col {
                  display: flex;
                  .image_cap {
                    width: 20%;
                    background-color: #ffffff;
                    margin: -90px 25px 40px;
                    padding: 12px;
                    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.16);
                    border-radius: 6px;
                    position: relative;
                    z-index: 99;
                    .profile_discription {
                      img {
                        width: 100%;
                        object-fit: cover;
                        border-radius: 8px;
                        object-position: 0 0;
                      }
                      .profile-text-01 {
                        font-size: 14px;
                        font-weight: 300;
                        line-height: 1.5;
                        margin-top: 10px;
                      }
                    }
                  }
                  .profile_header {
                    width: 70%;
                    background-color: #ffffff;
                    padding: 20px 0 0 15px;
                    .header_tagline {
                      display: flex;
                      .subheding_text {
                        margin-right: 50px;
                        width: 30%;
                        margin-bottom: 20px;
                      }
                      .header_icons {
                        display: flex;
                        width: 72%;
                        align-items: center;
                        padding-right: 50px;
                        position: relative;
                        a {
                          margin-right: 10px;
                        }
                        .arrow {
                          position: absolute;
                          right: 0;
                          top: 30px;
                          cursor: pointer;
                          @include respond-to("medium_lg") {
                            right: 15px;
                          }
                        }
                      }
                    }
                    .main-desc {
                      width: 85%;
                      font-size: 14px;
                      font-weight: 300;
                      line-height: 1.5;
                      padding-bottom: 100px;
                    }
                  }
                }
              }
            }
          }
        }
      }
      &.learn-more-about {
        margin-top: 150px;
        .content-inner-box {
          .company-values {
            .values-title {
              display: flex;
              align-items: center;
            }
            .value-text {
              .principles {
                padding: 40px 92px;
                .card_containar {
                  .leftside_text {
                    display: flex;
                    align-items: center;
                    .card_list_items {
                      ul {
                        li {
                          margin: 0;
                          font-weight: 600;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      //---------------Portfolio page commond element styles------------------
      .investment-profile-wrapper {
        .investment-main-content {
          margin-bottom: 10px;
          .discription_block_investment {
            width: 64%;
            margin: auto;
            // width: 60%;
            // margin: 0 auto;
            // padding: 0 100px 0 0;
            @include respond-to("medium_lg") {
              width: 80%;
              // padding: 0 0px;
              // margin: auto 145px;
            }
            @include respond-to("medium") {
              // width: 69%;
              // padding: 0;
            }
            @include respond-to("small") {
              width: 80%;
              // padding: 0;
              // margin: 0 auto;
            }
            .card_containar_investment {
              box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
              transition: 0.3s;
              width: 100%;
              /* height:156px; */
              background-color: #ffffff;
              border-top-right-radius: 8px;
              border-bottom-right-radius: 8px;
              border-top-left-radius: 8px;
              border-bottom-left-radius: 8px;
              &:hover {
                box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
              }
              .leftside_text_investment {
                display: grid;
                grid-template-columns: 0fr 1fr;
                @include respond-to("small") {
                  display: block;
                }
                .image_containar_investment {
                  height: 500px;
                  padding: 10px;
                  @include respond-to("medium") {
                    height: 300px;
                  }
                  @include respond-to("small") {
                    width: 100%;
                    height: 250px;
                    object-fit: cover;
                    padding: 0;
                    object-position: 0 0;
                  }
                }
              }
              .rightside_containar_investment {
                position: relative;
                display: flex;
                flex-direction: column;
                justify-content: center;
                .name_label {
                  margin: 30px 0px 0px -60px;
                  position: relative;
                  width: 320px;
                  border-radius: 8px;
                  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.16);
                  background-image: linear-gradient(103deg, #97df79 0%, #7bce76 69%);
                  @include respond-to("medium") {
                    margin-top: 30px;
                  }
                  @include respond-to("small") {
                    margin: 30px auto 0;
                    width: 85%;
                  }
                  img {
                    width: 100%;
                    @include respond-to("medium") {
                      height: 45px;
                    }
                  }
                  .lable_p {
                    color: #ffffff;
                    font-size: 30px;
                    font-weight: 600;
                    position: absolute;
                    z-index: 999;
                    display: flex;
                    flex-direction: column;
                    line-height: 30px;
                    text-align: left;
                    @include respond-to("medium") {
                      font-size: 18px;
                    }
                    @include respond-to("small") {
                      font-size: 14px;
                    }
                    span {
                      font-size: 14px;
                      font-weight: normal;
                    }
                    &.p1 {
                      top: 30px;
                      left: 40px;
                      @include respond-to("medium") {
                        top: 13px;
                      }
                    }
                    &.p2 {
                      top: 30px;
                      left: 40px;
                      @include respond-to("medium") {
                        top: 13px;
                      }
                    }
                    &.p3 {
                      top: 15px;
                      left: 40px;
                      @include respond-to("medium") {
                        top: 13px;
                      }
                      @include respond-to("small") {
                        text-align: left;
                        top: 15px;
                        line-height: 19px;
                      }
                    }
                    &.p4,
                    &.p5 {
                      top: 30px;
                      left: 40px;
                      @include respond-to("medium") {
                        top: 13px;
                        left: 30px;
                      }
                    }
                  }
                }
                .short-desc {
                  font-size: 14px;
                  padding: 25px;
                  width: 80%;
                }
                .brand-logos {
                  @include respond-to("medium") {
                    padding-bottom: 20px;
                  }
                  img {
                    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
                    border-radius: 8px;
                    width: 14%;
                    margin-left: 25px;
                    margin-right: 15px;
                    display: inline-block;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  